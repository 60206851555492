import styled from "styled-components";

import { colors } from "../../globals";

export const LineupList = styled.ul`
  list-style-type: none;
`;

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Line = styled.div`
  width: 3px;
  min-height: 20px;
  background-color: black;
  height: calc(50% - 37px);
`;

export const LineupItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const Talk = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  align-items: center;
`;

export const Speaker = styled.span`
  font-weight: bolder;
  font-style: italic;
`;

export const Name = styled.h3`
  font-size: 1.5em;
  margin: 0;
`;

export const Hour = styled.div`
  font-size: 1.5em;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.main.primary};
  font-weight: bolder;
`;

export const PictureWrapper = styled.div`
  border-radius: 10px;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border: 3px solid black;
`;

export const Picture = styled.img`
  width: 100%;
  height: 75px;
  object-fit: cover;
`;
