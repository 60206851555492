import styled from "styled-components";
import { borderStyle, shadowStyle } from "../../globals";

export const Container = styled.div`
  width: 98%;
  max-width: 1300px;
  margin: 8rem auto;
  background-color: #fdf3e6;
  border-radius: 15px;
  border: ${borderStyle()};
  box-shadow: ${shadowStyle()};
`;

export const WrapperContainer = styled.div`
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 98%;
  justify-content: center;
`;
