import React from "react"

import socialMedias from "../../contents/social-medias"
import { List, Item } from "./SocialMedias.styles"

const SocialMedias = () => (
  <List>
    {socialMedias.map(socialMedia => (
      <Item key={socialMedia.id}>
        <a href={socialMedia.link} target="_blank" rel="noreferrer">
          {socialMedia.icon}
        </a>
      </Item>
    ))}
  </List>
)

export default SocialMedias
