import styled from "styled-components"

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  list-style-type: none;
`

export const Item = styled.li`
  margin: 2em;
`

export const SupportImage = styled.img`
  width: 170px;
`

export const IWantToBeASponsor = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2em;
`
