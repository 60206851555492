import speakers from "./speakers"

const getSpeaker = name =>
  speakers.data
    .filter(speaker => speaker.name.toLowerCase() === name.toLowerCase())
    .shift()

export default {
  enabled: true,
  data: [
    {
      hour: "9h00",
      description: "Abertura",
    },
    {
      speaker: getSpeaker("Fabi Rodrigues"),
    },
    {
      speaker: getSpeaker("Nicholas Eduardo"),
    },
    {
      speaker: getSpeaker("Guilherme e Gabriel"),
    },
    {
      speaker: getSpeaker("Letícia Godoy"),
    },
    {
      speaker: getSpeaker("Martin Zlámal"),
    },
    {
      hour: "12h15",
      description: "Almoço",
    },
    {
      hour: "13h15",
      description: "Abertura tarde",
    },
    {
      speaker: getSpeaker("Samuel Monteiro"),
    },
    {
      speaker: getSpeaker("Marcelo Bissuh"),
    },
    {
      speaker: getSpeaker("Arthur Geron"),
    },
    {
      speaker: getSpeaker("Carlos Levir"),
    },
    {
      speaker: getSpeaker("Felipe Battistotti"),
    },
    {
      speaker: getSpeaker("Leandro Passos"),
    },
    {
      hour: "16h25",
      description: "Intervalo (20 min)",
    },
    {
      speaker: getSpeaker("Gabriel e Nicolas"),
    },
    {
      speaker: getSpeaker("Gabriel Rubens & Marcos Oliveira"),
    },
    {
      speaker: getSpeaker("Akinn Rosa"),
    },
    {
      hour: "18h45",
      description: "Desconferência",
    },
    {
      hour: "19h45",
      description: "Encerramento",
    },
  ],
}
