import Tc from "src/assets/images/sponsors/tc.png"
import Gather from "src/assets/images/sponsors/gather.png"
import Legiti from "src/assets/images/sponsors/legit.png"
import VTEX from "src/assets/images/sponsors/vtex.png"
import VanHack from "src/assets/images/sponsors/vanhack.png"
import TopTal from "src/assets/images/sponsors/toptal.png"
import Quanto from "src/assets/images/sponsors/quanto.png"

export const emeraldSponsors = [
  {
    id: "tc",
    name: "TC",
    image: Tc,
    site: "https://tc.com.br/",
  },
]

export const diamondSponsors = [
  {
    id: "gather",
    name: "Gather",
    image: Gather,
    site: "https://gather.town/",
  },
  {
    id: "legiti",
    name: "Legiti",
    image: Legiti,
    site: "https://www.legiti.com/",
  },
  {
    id: "vtex",
    name: "VTEX",
    image: VTEX,
    site: "https://vtex.com/br-pt/",
  },
  {
    id: "vanhack",
    name: "vanHack",
    image: VanHack,
    site: "https://vanhack.com/",
  },
  {
    id: "toptal",
    name: "TopTal",
    image: TopTal,
    site: "https://www.toptal.com/freelance-jobs",
  },
]

export const mediumSponsors = [
  {
    id: "quanto",
    name: "Quanto",
    image: Quanto,
    site: "https://go.quan.to/vagas",
  },
]

export const smallSponsors = null

export const extraSmallSponsors = null
