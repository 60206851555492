import React from "react"
import {
  FaSlack,
  FaTwitter,
  FaGithub,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa"

export default [
  {
    id: "slack",
    link: "https://react.now.sh/",
    icon: <FaSlack />,
  },
  {
    id: "twitter",
    link: "http://twitter.com/reactconfbr",
    icon: <FaTwitter />,
  },
  {
    id: "github",
    link: "https://github.com/react-brasil",
    icon: <FaGithub />,
  },
  {
    id: "facebook",
    link: "https://www.facebook.com/reactconf/",
    icon: <FaFacebook />,
  },
  {
    id: "instagram",
    link: "https://www.instagram.com/reactconfbr/",
    icon: <FaInstagram />,
  },
]
