import React from "react";

import { Container, Button, Link } from "../../components";
import { contact, supporters } from "../../contents";
import { List, Item, SupportImage, IWantToBeASponsor } from "./Support.styles";
import { ButtonType } from "../../components/Button";
import { AiOutlineMail } from "react-icons/ai";

export const Support = () => {
  if (!supporters.enabled) return null;

  const { data } = supporters;

  return (
    <Container title={"Apoio"} id="apoio">
      <List>
        {data.map((support) => (
          <Item key={support.id}>
            <Link href={support.link}>
              <SupportImage
                src={support.image}
                alt={support.name}
                title={support.name}
              />
            </Link>
          </Item>
        ))}
      </List>
      <IWantToBeASponsor>
        <Button
          type={ButtonType.primary}
          link={`mailto:${contact.email}`}
          icon={<AiOutlineMail />}
        >
          QUERO SER UM APOIADOR
        </Button>
      </IWantToBeASponsor>
    </Container>
  );
};
