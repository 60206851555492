import React, { FC } from "react";
import { ISponsor, SponsorsTypesProps } from ".";

import { Link } from "../../components";
import { diamondSponsors } from "../../contents";
import { SponsorList, SponsorItem, SponsorImage } from "./Sponsors.style";

const Diamond: FC<SponsorsTypesProps> = ({ list = diamondSponsors }) => {
  if (!list) return null;
  
  return (
    <SponsorList>
      {list.map((diamond) => (
        <SponsorItem key={diamond.id}>
          <Link href={diamond.site}>
            <SponsorImage
              src={diamond.image}
              alt={diamond.name}
              title={diamond.name}
              type="diamond"
            />
          </Link>
        </SponsorItem>
      ))}
    </SponsorList>
  );
};

export default Diamond;
