import styled from "styled-components"

import { SmallScreen } from "../../style/breakpoints"

export const List = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
`

export const Item = styled.li`
  margin: 0.2em;

  ${SmallScreen} {
    width: 80%;
  }
`
