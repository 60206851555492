import React, { FC, ReactElement } from "react";
import { FaYoutube } from "react-icons/fa";

import { conference } from "../../contents";
import {
  PreviousYearImage,
  PreviousYearItem,
  PreviousYearMidia,
  StyledPreviousYearsVideosList,
  VideoIcon,
} from "./PreviousYearsVideosList.styles";

export const PreviousYearsVideosList: FC<{ setOpenedVideo?: any }> = ({
  setOpenedVideo,
}): ReactElement => (
  <StyledPreviousYearsVideosList>
    {conference.videos.previousYears.map((video) => (
      <PreviousYearItem onClick={() => setOpenedVideo(video.videoId)}>
        <PreviousYearMidia>
          <PreviousYearImage src={video.thumb} />

          <VideoIcon>
            <FaYoutube />
          </VideoIcon>
        </PreviousYearMidia>

        <span>{video.year}</span>
      </PreviousYearItem>
    ))}
  </StyledPreviousYearsVideosList>
);
