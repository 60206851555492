import React, { ReactElement } from "react"

import { conference } from "../../contents"

export interface ILink {
 href: string,
 children: ReactElement
}

const UTM = `?utm_source=reactconfbr-site&utm_medium=logo&utm_campaign=reactconfbr-${conference.year}`

export const Link = ({ href, children }) => (
  <a href={`${href}${UTM}`} target="blank" rel="noreferrer" data-testid="link">
    {children}
  </a>
)
