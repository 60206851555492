import styled from "styled-components"

import { BiggerThanMedium, BiggerThanLarge } from "../../style/breakpoints"
import { colors } from "../../globals"

export const VideosContainer = styled.div`
  padding: 0 1em;

  ${BiggerThanLarge} {
    width: 80%;
  }
`

export const ConferenceContainer = styled.div`
  text-align: center;
  margin-top: 5rem;

  ${BiggerThanLarge} {
    width: 80%;
    margin-top: inherit;
  }
`

export const IntroVideo = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;

  iframe {
    width: 100%;
    border-radius: 10px;
    border: 2px solid rgb(218 218 218 / 14%);
    height: 300px;

    ${BiggerThanMedium} {
      height: 490px;
    }
  }
`

export const Subtitle = styled.h3`
  color: ${colors.main.primary};
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 4px;
  margin: 0;
  font-size: 2rem;
`

export const Bio = styled.p`
margin-top: 4rem;
  font-size: 1.5rem;
`

export const Technologies = styled.p`
  padding: 1em 3em 0;
  margin-bottom: 4rem;
`
