import React from "react";
import { Logo } from "../../components";
import { conference } from "../../contents";

import { BoxBar, FooterWrapper, Frame, LoadingBar, MainContent, Spacer } from "./Hero.styles";

export const InitialHero = () => {
  return <Frame>
      <MainContent>
          
          <Logo white />
          
          <Spacer />
          
          <LoadingBar>
          <BoxBar/>
          <BoxBar/>
          <BoxBar/>
          </LoadingBar>
      </MainContent>
      

      <FooterWrapper>
          <h1>React Conf BR {conference.year}</h1><h1>{conference.date}</h1>
      </FooterWrapper>
      
      </Frame>;
};
