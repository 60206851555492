import He4rtDevs from "../assets/images/supporters/he4rtdevs.png"
import LetGodoy from "../assets/images/supporters/letgodoy-color.png"
import Arquivei from "../assets/images/supporters/rsz_arquivei.png"
import Rocketseat from "../assets/images/supporters/rocketseat.png"

export default {
  enabled: true,
  data: [
    {
      id: "ley-godoy",
      name: "Let Godoy",
      image: LetGodoy,
      link: "https://letgodoy.com",
    },
    {
      id: "arquivei",
      name: "Arquivei",
      image: Arquivei,
      link: "https://arquivei.com.br/vagas/",
    },
    // {
    //   id: "shawee",
    //   name: "Shawee",
    //   image: Shawee,
    // },
    // {
    //   id: "rocketseat",
    //   name: "Rocketseat",
    //   image: Rocketseat,
    // },
  ],
}
