import React, { FC, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export interface ISEO {
  description?: string;
  lang?: string;
  meta?: any[];
  title: string;
  image?: any;
  url?: string;
}

export const SEO: FC<ISEO> = ({
  description = "",
  lang = "pt-br",
  meta = [],
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`${site.siteMetadata.title} | %s`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: site.siteMetadata.image,
          },
          {
            property: `og:url`,
            content: site.siteMetadata.url,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:image`,
            content: site.siteMetadata.image,
          },
          {
            name: `twitter:url`,
            content: site.siteMetadata.url,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            charset: "utf-8",
          },
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1",
          },
          {
            httpEquiv: "cache-control",
            content: "no-cache, private, max-age=0",
          },
          {
            name: "keywords",
            content:
              "HTML, CSS, XML, XHTML, JavaScript, React, JS, React JS, React Native, GraphQL, Apollo, Jest, Reason, Docz, Redux, Relay, Flow, RxJS, Styled Components, Router, Navigation, Enzyme, Node, TypeScript, Deno, Webpack, Wasm, Prepack, Storybook, Reselect, Sagas, Thunk, Next, Logics, Immutable, React VR, MobX, Mocha, CSSModules, JSS, Actionz, Redux Form, Flux, Nightwatch, Mocha, Ramda, Glamorous, Reflux, Observable, Inferno, Lodash, Isomorphic, SSR, Babel, Leaflet",
          },
          {
            httpEquiv: "expires",
            content: "0",
          },
          {
            httpEquiv: "pragma",
            content: "no-cache",
          },
        ].concat(meta)}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          href="https://fonts.googleapis.com/css2?family=Rajdhani&family=Ubuntu+Mono&family=VT323&display=swap"
          rel="stylesheet"
        />
      </Helmet>
    </Fragment>
  );
};
