import thumbConf2017 from "../assets/images/conf-2017.png"
import thumbConf2018 from "../assets/images/conf-2018.png"
import thumbConf2019 from "../assets/images/conf-2019.png"

export default {
  year: "2021",
  date: "16 de Outubro de 2021",
  dateNumber: "16/10/2021",
  bio: `A maior conferência do ecossistema React da América Latina. Como nas conferências dos Estados Unidos e Europa, reunimos desenvolvedores, empresas e estudantes de tecnologia para compartilhar experiências no desenvolvimento de aplicações reativas.`,
  technologies: `React Native, GraphQL, Apollo, Jest, Reason, Docz, Redux, Relay, Flow, RxJS, Styled Components, Router, Navigation, Enzyme, Node, TypeScript, Deno, Webpack, Wasm, Prepack, Storybook, Reselect, Sagas, Thunk, Next, Logics, Immutable, React VR, MobX, Mocha, CSSModules, JSS, Actionz, Redux Form, Flux, Nightwatch, Mocha, Ramda, Glamorous, Reflux, Observable, Inferno, Lodash, Isomorphic, SSR, Babel, Leaflet.`,
  videos: {
    previousYears: [
      {
        year: 2019,
        videoId: "sx-6ZElybpU",
        thumb: thumbConf2019,
      },
      {
        year: 2018,
        videoId: "x9K78rfG3bo",
        thumb: thumbConf2018,
      },
      {
        year: 2017,
        videoId: "N-vACHJypCM",
        thumb: thumbConf2017,
      },
    ],
    intro: "https://www.youtube.com/embed//videoseries?list=PLVNlqEw0GNauq3lrezxdDmOz5_QJJy6IB",
  },
}
