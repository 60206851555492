import React, { FC } from "react";
import { ISponsor, SponsorsTypesProps } from ".";

import { Link } from "../../components";
import { smallSponsors } from "../../contents";
import { SponsorList, SponsorItem, SponsorImage } from "./Sponsors.style";

const Smalls: FC<SponsorsTypesProps> = ({ list = smallSponsors }) => {
  if (!list) return null;

  return (
    <SponsorList>
      {list.map((small) => (
        <SponsorItem key={small.id}>
          <Link href={small.site}>
            <SponsorImage
              src={small.image}
              alt={small.name}
              title={small.name}
              type="small"
            />
          </Link>
        </SponsorItem>
      ))}
    </SponsorList>
  );
};

export default Smalls;
