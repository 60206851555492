import React from "react"

import { Button } from "../../components"
import { meetups } from "../../contents"
import { List, Item } from "./Meetups.styles"

const Meetups = () => (
  <List>
    {meetups.map(meetup => (
      <Item key={meetup.id}>
        <Button link={meetup.link} type="tertiary">
          {meetup.name}
        </Button>
      </Item>
    ))}
  </List>
)

export default Meetups
