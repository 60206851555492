import React, { useState, useEffect, FC } from "react"

import { logoPaths } from "./logo-paths"

export const ReactBrAnimation:FC<{dark?: boolean, size?: number}> = ({dark, size}) => {
  const [index, setIndex] = useState(0)

  const logo = logoPaths(dark, size)

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = index === logo.length - 1 ? 0 : index + 1

      setIndex(newIndex)
    }, 400)

    return () => clearInterval(interval)
  }, [index])

  return logo[index]
}

