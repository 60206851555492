import styled from "styled-components"

import { colors } from "../../globals"

export const FooterContent = styled.div`
  width: 100%;
  flex-direction: column;
  font-size: 1.3rem;
  justify-content: flex-start;
  padding-left: 3%;
`

export const List = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  width: 100%;
  flex-direction: column;
`

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  text-align: left;
`

export const ItemLabel = styled.span`
  color: ${colors.dark};
`

export const ItemValue = styled.a`
  color: ${colors.main.primary};
  text-decoration: none;
  margin-left: 0.5rem;
`

export const GatsbyWrapper = styled.div`
  display: flex;
  padding-left: 3%;

  a {
    color: ${colors.main.primary};
    font-size: 1.5rem;
    text-decoration: none;
  }
`
