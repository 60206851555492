import React, { FC, useState } from "react";
import ModalVideo from "react-modal-video";
import { FaYoutube } from "react-icons/fa";
// import NextSpeaker from "../../assets/images/speakers/next.gif";

import { Container } from "../../components";

import { speakers } from "../../contents";

import {
  SpeakersList,
  Speaker,
  PictureWrapper,
  Picture,
  Name,
  Talk,
} from "./Speakers.styles";

export interface ISpeaker {
  name: string;
  talk: string;
  videoId: string;
  picture: string;
  talkLink?: string;
}

export const Speakers: FC<{ list?: { data?: ISpeaker[]; enabled: boolean } }> =
  ({ list = speakers }) => {
    if (!list.enabled) return null;

    const [openedVideo, setOpenedVideo] = useState(null);

    const { data } = list;

    return (
      <Container title={"Palestrantes"} id="palestrantes">
        <SpeakersList>
          {data.map((speaker, key) => (
            <Speaker key={key}>
              <PictureWrapper onClick={() => setOpenedVideo(speaker.videoId)}>
                <FaYoutube />
                <Picture
                  src={speaker.picture}
                  alt={speaker.name}
                  title={speaker.name}
                />
              </PictureWrapper>
              <Name>{speaker.name}</Name>
              <Talk
                href={speaker.talkLink}
                target="_blank"
                talkLink={speaker.talkLink}
              >
                {speaker.talk}
              </Talk>
            </Speaker>
          ))}

          {/*<Speaker>*/}
          {/*  <PictureWrapper>*/}
          {/*    <Picture*/}
          {/*      src={NextSpeaker}*/}
          {/*      alt={NextSpeaker.name}*/}
          {/*      title={NextSpeaker.name}*/}
          {/*    />*/}
          {/*  </PictureWrapper>*/}
          {/*  <Name>Outra pessoa</Name>*/}
          {/*  <Talk>anúncio em breve...</Talk>*/}
          {/*</Speaker>*/}
        </SpeakersList>

        <ModalVideo
          channel="youtube"
          isOpen={!!openedVideo}
          videoId={openedVideo}
          onClose={() => setOpenedVideo(null)}
        />
      </Container>
    );
  };
