import React, { FC }  from "react"
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai"

import { contact } from "../../contents"
import { Button, Container } from "../../components"

import { ButtonType } from "../../components/Button"

export const Contact: FC = () => (
  <Container title={"Contato"}  id="contato">
      <Button
        type={ButtonType.primary}
        icon={<AiOutlineMail />}
        link={`mailto:${contact.email}`}
        inverse
      >
        ENVIAR E-MAIL
      </Button>
      {/* <Button
        type="primary"
        icon={<AiOutlineWhatsApp />}
        link={`https://api.whatsapp.com/send?phone=${contact.phoneNumber}&text=${contact.whatsAppMessage}`}
        inverse
      >
        ENVIAR MENSAGEM
      </Button> */}
  </Container>
)
