import styled from "styled-components"

import { colors } from "../../globals"

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Item = styled.li`
  width: 60px;
  height: 60px;
  margin: 0.2em;
  font-size: 3em;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: ${colors.main.primary};
    text-decoration: none;
  }

  &:hover a {
    color: ${colors.main.tertiary};
  }
`
