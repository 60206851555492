import React from "react";

import Logo from "../../assets/images/reactpainel2021.png";
import {Button, ButtonType, Container} from "../../components";
import { conference } from "../../contents";

import { ConfDate, LogoImg } from "./Home.styles";
import {MdLiveTv} from "react-icons/md";

export const Home = () => (
  <Container title={"Home"} id="home">
    <LogoImg src={Logo} alt="React Conf BR 2021"/>

    <ConfDate>{conference.date}</ConfDate>
      <Button
          type={ButtonType.primary}
          icon={<MdLiveTv />}
          link={`https://youtu.be/g7nyrdwRIFc`}
          style={{ margin: "2rem auto" }}
      >
          Assista por aqui
      </Button>
  </Container>
);
