import React from "react"

import {
  FooterContent,
  List,
  Item,
  ItemValue,
  ItemLabel,
  GatsbyWrapper,
} from "./Footer.styles"

export const Footer = () => (
  <footer>
    <FooterContent>
      <List>
        <Item>
          <ItemLabel>PRODUÇÃO</ItemLabel>
          <ItemValue>Átomos Nativos</ItemValue>
        </Item>
        <Item>
          <ItemLabel>COMPROMISSO</ItemLabel>
          <ItemValue
            href="https://reactconf.com.br/files/React_ManualConduta.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Código de Conduta
          </ItemValue>
        </Item>
        <Item>
          <ItemLabel>BRANDING</ItemLabel>
          <ItemValue
            href="https://informatica.sp.senai.br/"
            target="_blank"
            rel="noreferrer"
          >
            Senai
          </ItemValue>
        </Item>
      </List>
    </FooterContent>
    <GatsbyWrapper>
      <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
        Feito com Gatsby
      </a>
    </GatsbyWrapper>
  </footer>
)
