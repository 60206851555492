import React, { useState } from "react"
import ModalVideo from "react-modal-video"

import { conference } from "../../contents"
import {PreviousYearsVideosList} from "./PreviousYearsVideosList"
import {
  VideosContainer,
  ConferenceContainer,
  IntroVideo,
  Subtitle,
  Bio,
  Technologies,
} from "./Conference.styles"
import { Container, ReactBrAnimation } from "../../components"

export const Conference = () => {
  const [openedVideo, setOpenedVideo] = useState(null)

  return (
    <Container id="evento" title={"A CONFERÊNCIA"}>
      <ConferenceContainer>
        <ReactBrAnimation dark />
        <Subtitle>BLEEDING EDGE DEVELOPMENT</Subtitle>
        <Bio>{conference.bio}</Bio>
        <Technologies>{conference.technologies}</Technologies>
      </ConferenceContainer>
      <VideosContainer>
        <IntroVideo>
          <iframe
            src={conference.videos.intro}
            frameBorder="0"
            title="video-intro-conf"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          2020
        </IntroVideo>
        <PreviousYearsVideosList setOpenedVideo={setOpenedVideo} />
      </VideosContainer>
      
      <ModalVideo
        channel="youtube"
        isOpen={!!openedVideo}
        videoId={openedVideo}
        onClose={() => setOpenedVideo(null)}
      />
    </Container>
  )
}

