import { createGlobalStyle } from "styled-components"
import "react-modal-video/css/modal-video.min.css"

import { SmallScreen } from "../../style/breakpoints"
import { fontFamilyTitle, colors, fontFamilyBody } from "../../globals"

export const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Rajdhani&family=Ubuntu+Mono&family=VT323&display=swap');
    body {
        margin: 0;
        padding: 0;
        overflow-x: auto;
        box-sizing: border-box;
        font-family: ${fontFamilyBody};
        background-color: ${colors.main.tertiary};
        overflow-x: hidden;
        color: ${colors.dark}

        ${SmallScreen} {
            font-size: 12px;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: ${fontFamilyTitle};
    }
`