import { fontFamilyStyle } from "./../../globals";
import styled from "styled-components";
import { colors } from "../../globals";

export const Frame = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.dark};
  color: ${colors.white};
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  margin: 0;
  box-sizing: border-box;
  

  animation-name: fadeout;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  overflow-anchor: none;

  @keyframes fadeout {
    from {
      opacity: 1;
      display: block;
      padding: 3rem;
      padding-right: 4rem;

      overflow: hidden;
    }
    to {
      opacity: 0;
      display: none;
      z-index: -1000;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100vw;
  justify-content: space-between;
  display: flex;
  align-self: flex-end;
  position: absolute;
  left: 0;
  bottom: 3rem;
  box-sizing: border-box;
  padding-left: 3rem;
  padding-right: 4rem;

  h1 {
    font-family: ${fontFamilyStyle};
  }
`;

export const MainContent = styled.div`
  width: 100%;
  height: calc(100% - 10rem);
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 40rem;
    min-width: 35%;
    max-width: 80%;
    margin: auto;
  }
`;

export const Spacer = styled.div`
  width: 1rem;
  height: 8rem;
  display: flex;
`;

export const LoadingBar = styled.div`
  width: 20rem;
  height: 1rem;
  border: 3px solid ${colors.white};
  border-radius: 0.6rem;
  margin: 0 auto;
  padding: 2px 1px;
  overflow: hidden;
  font-size: 0;
`;

export const BoxBar = styled.div`
  width: 0.9rem;
  height: 100%;
  background: linear-gradient(
    to bottom,
    #2838c7 0%,
    #5979ef 17%,
    #869ef3 32%,
    #869ef3 45%,
    #5979ef 59%,
    #2838c7 100%
  );
  display: inline-block;
  margin-right: 2px;
  animation: loader 2s infinite;
  animation-timing-function: linear;

  @keyframes loader {
    0% {
      transform: translate(-3rem);
    }
    100% {
      transform: translate(22rem);
    }
  }
`;
