import React, { ReactElement } from "react";
import { colors } from "../../globals";

import {
  ButtonsDecoration,
  TitleSection,
  TitleContainer,
  FalseButton,
} from "./Title.styles";

export const Title = ({ children }): ReactElement => (
  <TitleContainer>
    <TitleSection>{children}</TitleSection>
    <ButtonsDecoration>
      <FalseButton color={colors.light.red} />
      <FalseButton color={colors.light.yellow} />
      <FalseButton color={colors.light.green} />
    </ButtonsDecoration>
  </TitleContainer>
);
