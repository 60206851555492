import React from "react"

import { Wrapper, ConfName, ConfEdition } from "./Logo.styles"
import { conference } from "../../contents"

export const Logo = (props) => (
  <Wrapper>
    <ConfName {...props}>
      REACT<span>CONF</span>
    </ConfName>
    <ConfEdition>BRASIL {conference.year}</ConfEdition>
  </Wrapper>
)