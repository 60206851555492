import React from "react"

import { Container, Button } from "../../components"
import { AiOutlineFilePdf } from "react-icons/ai"
import { WrapperButtons } from "./Callforpapers.styles"
import { ButtonType } from "../../components/Button"

export const Callforpapers = () => (
  <Container title={"Call for papers"} id="c4p">
      <Button
        type={ButtonType.primary}
        icon={<AiOutlineFilePdf />}
        link={`/files/manual-c4p.pdf`}
        inverse
        style={{ margin: "auto" }}
      >
        MANUAL DO PALESTRANTE
      </Button>
  </Container>
)
