import Akinn from "../assets/images/speakers/akinn.jpg";
import GabrielMarcos from "../assets/images/speakers/gabriel_marcos.jpg";
import FabiRodrigues from "../assets/images/speakers/fabi_rodrigues.jpg";
import Let from "../assets/images/speakers/let-godoy.jpg";
import Nich from "../assets/images/speakers/nich.jpg";
import GabrielNicolas from "../assets/images/speakers/gabriel-nicolas.jpg";
import Samuel from "../assets/images/speakers/samuel.jpg";
import GuilhermeGabriel from "../assets/images/speakers/guilherme-gabriel.jpg";
import Bissuh from "../assets/images/speakers/bissuh.jpg";
import Arthur from "../assets/images/speakers/arthur.jpg";
import Carlos from "../assets/images/speakers/carlos.jpg";
import Felipe from "../assets/images/speakers/felipe.jpg";
import Leandro from "../assets/images/speakers/leandro.jpg";
import Martin from "../assets/images/speakers/martin.jpg";

export default {
  enabled: true,
  data: [
    {
      name: "Akinn Rosa",
      talk: "Arquitetura do React Native em 2021",
      videoId: "EybU458fTtk",
      picture: Akinn,
    },
    {
      name: "Gabriel Rubens & Marcos Oliveira",
      talk: "ES2077 (ReScript)",
      videoId: "DwII9ImCl3Y",
      picture: GabrielMarcos,
    },
    {
      name: "Fabi Rodrigues",
      talk: "Descomplicando Relay",
      videoId: "xcgnCo0D5I8",
      picture: FabiRodrigues,
    },
    {
      name: "Letícia Godoy",
      talk: "Time Fullstack pequeno, é possível?",
      videoId: "VSUdes7bvsM",
      picture: Let,
    },
    {
      name: "Nicholas Eduardo",
      talk: "Soft skills para devs, de um jeito que você nunca viu",
      videoId: "C-llajs1Sig",
      picture: Nich,
    },
    {
      name: "Gabriel e Nicolas",
      talk: "Desconstruindo monolitos com BFFs",
      videoId: "gMAR-w7O668",
      picture: GabrielNicolas,
    },
    {
      name: "Samuel Monteiro",
      talk: "Não use apenas booleanos para representar o estado do seu componentes",
      videoId: "3W2VTnCMMqc",
      picture: Samuel,
    },
    {
      name: "Guilherme e Gabriel",
      talk: "Como fizemos nossa web app resiliente à offline com Gatsby, React e Relay",
      videoId: "YPPKqx25RDs",
      picture: GuilhermeGabriel,
    },
    {
      name: "Marcelo Bissuh",
      talk: "JS is for Traders",
      videoId: "Mnkgu9iHFa4",
      picture: Bissuh,
    },
    {
      name: "Arthur Geron",
      talk: "Context API Selectors: Evitando efeitos colaterais indesejados do Context API com Selector nativo",
      videoId: "GCuwZHktPzc",
      picture: Arthur,
    },
    {
      name: "Carlos Levir",
      talk: "Criando seu primeiro módulo nativo no React Native",
      videoId: "caFAtTi6IQA",
      picture: Carlos,
    },
    {
      name: "Felipe Battistotti",
      talk: "SWR na sua aplicação",
      videoId: "n0Vv0wAneCY",
      picture: Felipe,
    },
    {
      name: "Leandro Passos",
      talk: "How to prepare for a successful interview",
      videoId: "T15HBGEhCBY",
      picture: Leandro,
    },

    {
      name: "Martin Zlámal",
      talk: "Translate your React apps like Facebook engineers",
      videoId: "OQAPCwvsIMY",
      picture: Martin,
    },
    //ACABOU, É TETRA!!!!!
  ],
};
