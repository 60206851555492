import styled from "styled-components";

import { colors } from "../../globals";

export const StyledPreviousYearsVideosList = styled.ul`
  padding: 0;
  list-style-type: none;
  display: flex;
  width: 100%;
`;

export const VideoIcon = styled.div`
  position: absolute;
  color: rgb(255 255 255 / 38%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
  }
`;

export const PreviousYearItem = styled.li`
  cursor: pointer;
  text-align: center;
  height: 160px;
  width: 100%;
  justify-content: center;

  &:hover {
    img {
      filter: grayscale(1);
    }
  }

  &:not(:last-child) {
    margin-right: 1.5em;
  }

  span {
    font-weight: bold;
    font-size: 1.3rem;
  }
`;

export const PreviousYearImage = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid rgb(218 218 218 / 14%);
  transition: filter 200ms ease;
  margin: auto;
`;

export const PreviousYearMidia = styled.div`
  display: flex;
  position: relative;
`;
