import styled from "styled-components";

import { colors, borderStyle, shadowStyle } from "../../globals";

export const Btn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none;
  font-weight: bold;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: box-shadow 400ms ease;
  transition: transform 400ms ease;
  text-decoration: none;
  border: ${borderStyle()};
  box-shadow: ${shadowStyle()};
  color: ${colors.dark};
  background-color: ${({ color }) => (color ? colors.light[color] : colors.light.green)};
  padding: 0.6em ${({ hasIcon }) => (hasIcon ? `1.0em` : `1.7em`)};
  max-width: 320px;
  margin: 0.5rem;

  &:hover {
    box-shadow: ${shadowStyle(8)};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  font-size: 1.4em;
`;
