import React from "react";
import styled from "styled-components";

import { Container, Button, ButtonType } from "../../components";

import { MdWork } from "react-icons/md";

export const Go = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const title = params.get("title");
  const urlRedirect = params.get("url");
  console.log(title, urlRedirect);

  return (
    <Container title={title} id="redirect">
      <Button
        type={ButtonType.primary}
        link={urlRedirect.toString()}
        icon={<MdWork />}
        id="vagas"
      >
        {title}
      </Button>
    </Container>
  );
};
