import React, { ReactElement } from "react";
import { Title } from "../Title";

import {
  Container as StyledContainer,
  WrapperContainer,
} from "./Container.styles";

export const Container = ({ children, title, ...props }): ReactElement => (
  <StyledContainer {...props}>
    <Title>{title}</Title>
    <WrapperContainer>{children}</WrapperContainer>
  </StyledContainer>
);
