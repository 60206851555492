import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  SEO,
  GlobalStyles,
  Menu,
} from "../components";

import {
  Callforpapers,
  Community,
  Conference,
  Contact,
  Home,
  Lineup,
  Speakers,
  Sponsors,
  Support,
  InitialHero,
  Go,
} from "../containers";

import { sessions } from "../contents";

function IndexPage({ location }) {
  const params = new URLSearchParams(location.search);
  const title = params.get("title");
  const url = params.get("url");

  return (
    <main>
      <SEO title="2021" />
      <GlobalStyles />
      {title && url ? (
        <Go location={location} />
      ) : (
        <>
          {sessions.home && <Home />}
          {sessions.conference && <Conference />}
          {sessions.speakers && <Speakers />}
          {sessions.lineup && <Lineup />}
          {sessions.callforpapers && <Callforpapers />}
          {sessions.sponsors && <Sponsors />}
          {sessions.support && <Support />}
          {sessions.community && <Community />}
          {sessions.contact && <Contact />}
          {sessions.menu && <Menu />}
          {sessions.hero && <InitialHero />}
        </>
      )}
    </main>
  );
}

export default IndexPage;
