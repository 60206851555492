import React from "react"

import { Container } from "../../components"
import SocialMedias from "./SocialMedias"
import Meetups from "./Meetups"

export const Community = () => (
  <Container title={"Comunidade"} id="comunidade">
    <SocialMedias />
    <Meetups />
  </Container>
)
