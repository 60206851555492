import { fontFamilyTitle } from './../../globals';
import styled from "styled-components"

import { colors } from "../../globals"
import { BiggerThanXLarge } from "../../style/breakpoints"

export const Wrapper = styled.div`
  text-align: center;
  margin-top: 5rem;
  font-family: ${fontFamilyTitle};

  ${BiggerThanXLarge} {
    margin-top: 10rem;
  }
`

export const ConfName = styled.h1`
  color: ${(white: boolean):string => white? colors.white : colors.dark};
  font-weight: 900;
  font-size: 5em;
  margin: 0;
  font-family: ${fontFamilyTitle};

  span {
    color: ${colors.main.tertiary};
  }
`

export const ConfEdition = styled.h4`
  margin: 0;
  color: ${colors.main.tertiary};
  font-weight: 900;
  font-size: 1.5rem;
  position: relative;
  font-family: ${fontFamilyTitle};

  &:after {
    content: "";
    top: -11px;
    left: 50%;
    position: absolute;
    height: 2px;
    width: 200px;
    transform: translateX(-100px);
    background-color: ${colors.main.tertiary};
  }
`
