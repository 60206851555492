import styled from "styled-components";

import { borderStyle, colors, shadowStyle } from "../../globals";

export const TitleContainer = styled.div`
  width: 100%;
  margin: 0;
  border-bottom: ${borderStyle()};
  display: flex;
  justify-content: space-between;
`;

export const TitleSection = styled.h1`
  color: ${colors.dark};
  text-align: left;
  font-size: 2rem;
  margin: 0;
  padding: 0.5rem 2rem;
  text-transform: uppercase;

  /* &:after {
    content: '';
    height: 5px;
    bottom: -10px;
    background-image: linear-gradient(
      to right,
      ${colors.main.secondary},
      ${colors.main.primary}
    );
    left: 0;
    position: absolute;
    bottom: 5px;
    width: 100%;
  } */
`;

export const ButtonsDecoration = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`;

export const FalseButton = styled.div`
  background-color: ${({color}) => color};
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: ${borderStyle(2)};
  box-shadow: ${shadowStyle(3)};
  margin: 5px;
`;