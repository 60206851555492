export default [
  {
    id: 0,
    name: "Women that React/SP",
    city: "São Paulo",
    link: "https://www.meetup.com/pt-BR/Women-that-React",
  },
  {
    id: 1,
    name: "Meetup Salvador/BA",
    city: "Salvador",
    link: "https://www.facebook.com/reactssa/",
  },
  {
    id: 2,
    name: "Meetup Belo Horizonte/MG",
    city: "Belo Horizonte",
    link: "https://www.meetup.com/reactbh/",
  },
  {
    id: 3,
    name: "Meetup Curitiba/PR",
    city: "Curitiba",
    link: "https://www.meetup.com/pt-BR/ReactJS-CWB/",
  },
  {
    id: 4,
    name: "Meetup Maringá/PR",
    city: "Maringa",
    link: "https://www.meetup.com/pt-BR/React-Maringa/",
  },
  {
    id: 5,
    name: "Meetup Rio de Janeiro/RJ",
    city: "Rio de Janeiro",
    link: "https://www.meetup.com/pt-BR/React-Rio-de-Janeiro/",
  },
  {
    id: 6,
    name: "Meetup Porto Alegre/RS",
    city: "Porto Alegre",
    link: "https://www.meetup.com/React-Porto-Alegre/",
  },
  {
    id: 7,
    name: " Meetup Florianópolis/SC",
    city: "Florianopolis",
    link: "https://www.meetup.com/ReactJS-Floripa/",
  },
  {
    id: 8,
    name: "Meetup Joinville/SC",
    city: "Joinville",
    link: "https://www.meetup.com/React-Joinville/",
  },
  {
    id: 9,
    name: "Meetup São Paulo/SP",
    city: "São Paulo",
    link: "https://www.meetup.com/ReactJS-SP/",
  },
  {
    id: 10,
    name: "Meetup Goiânia/GO",
    city: "Goiânia",
    link: "https://www.meetup.com/pt-BR/React-Goiania/",
  },
  {
    id: 11,
    name: "Meetup Vila Velha/ES",
    city: "Vila Velha",
    link: "https://www.meetup.com/pt-BR/React-Meetup-ES/",
  },
]
