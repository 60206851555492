export const sessions = {
    home: true,
    conference: true,
    speakers: true,
    lineup: true,
    callforpapers: false,
    sponsors: true,
    support: true,
    community: true,
    contact: true,
    menu: true,
    footer: true,
    hero: true,
    tickets: false,
    live: true
}