import React from "react";

import {
  LineupList,
  LineupItem,
  LineupTalk,
  Speaker,
  Name,
  Talk,
  Picture,
  PictureWrapper,
  Hour,
  Line,
  LineWrapper,
} from "./Lineup.styles";
import { lineup } from "../../contents";
import { Container } from "../../components";

export const Lineup = () => {
  if (!lineup.enabled) return null;

  const { data } = lineup;

  const lastItem = data.length -1

  return (
    <Container title={"Lineup"} id="lineup">
      <LineupList>
        {data.map((talk, key) => (
          <>
            <LineupItem key={key}>
              <LineWrapper>
                {key === 0 ? null : <Line />}
                <PictureWrapper>
                  {talk.speaker ? (
                    <Picture
                      src={talk.speaker.picture}
                      alt={talk.speaker.name}
                      title={talk.speaker.name}
                    />
                  ) : (
                    <Hour>{talk.hour}</Hour>
                  )}
                </PictureWrapper>

                {key === lastItem ? null : <Line />}
              </LineWrapper>
              <Talk>
                {!!talk.speaker ? (
                  <Name>
                    {talk.speaker.talk} com{" "}
                    <Speaker>{talk.speaker.name}</Speaker>
                  </Name>
                ) : (
                  <Name>{talk.description}</Name>
                )}
              </Talk>
            </LineupItem>
          </>
        ))}
      </LineupList>
    </Container>
  );
};
