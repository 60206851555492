export const fontFamilyTitle = '"Rajdhani", sans-serif'
export const fontFamilyStyle = '"VT323", monospace';
export const fontFamilyBody = '"Ubuntu Mono", monospace';

export const colors = {
  dark: "#0a040a",
  white: "#FDF3E6",
  light: {
    primary: "#ffffff",
    secondary: "#dadada",
    red: "#FBAF9B",
    yellow: "#FAF7E4",
    green: "#9DF3B7"
  },
  main: {
    primary: "#d53a9d",
    secondary: "#743ad5",
    tertiary: "#00a9ff",
  },
}

export const borderStyle = (size = 3) => `${size}px solid ${colors.dark}`

export const shadowStyle = (distance = 5) => `${distance}px ${distance}px 0 ${colors.dark}`

export const toSubscribeLink =
  "https://www.sympla.com.br/react-conf-brasil-2020__910432"

export const storeLink = "http://loja.reactconf.com.br/"

export const liveLink = "https://youtu.be/1aZZEO9Jhng"