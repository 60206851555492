import React, { ReactElement } from "react"
import p from "prop-types"

import { Btn, IconWrapper } from "./Button.styles"

export enum ButtonType {
  primary = "primary", 
  secondary = "secondary", 
  tertiary = "tertiary"
}

export interface IButton {
  children: any, 
  link: string, 
  type: ButtonType, 
  inverse?: boolean, 
  icon?: ReactElement,
  style?: Record<string, any>,
  id?: string
}

export const Button = ({ children, link, type, inverse, icon, style, id }:IButton) => {
  const hasIcon = !!icon

  return (
    <Btn
      id={id}
      href={link}
      type={type}
      inverse={inverse}
      hasIcon={hasIcon}
      target="_blank"
      role="button"
      style={style}
    >
      {!!hasIcon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </Btn>
  )
}
